input {
    width: 100%;
    max-width: 500;
    flex-grow: 1;
    height: 30px;
    padding: 12px 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    font-size: 1rem;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input:focus {
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.highlight {
    background: url(//s2.svgbox.net/pen-brushes.svg?ic=brush-1&color=E6E6FA);
    margin: -2px -6px;
    padding: 2px 6px;
}