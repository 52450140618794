.table_conatiner{
    width: 100%;
    margin: 0 auto;
    margin-top: 3rem;

    table{
        border-collapse: collapse;
        font-size: 1.2rem;
        margin: 2rem 0;
    }
    th , td{
        border: 1px solid #cad4d7;
        text-align: center;
        //padding: 0.4rem;
        width: 10%;
        font-size: 1rem;
    }

    .headingtd{
        background-color: #edf2f9;
    }

    .nesttd{

        *{
            border: none;
        }
    }

    .totalheading{
        background-color: #edf2f9;
        font-weight: bold;
    }
    .total{
        border: none;
        font-weight: bold;
    }

    .noborder{
        *{
            border: none;
        }
    }
    .nobold{
        font-weight: normal;
    }
    .dataset{
      //border: red solid 1px;
      float: left;
      width: 60%;
      padding: 10px 0 10px 0;
      margin-right: 5%;
      margin-left: 5%;
    }
    .precentage{
      //border: red solid 1px;
      background-color: #edf2f9;
      float: left;
      width: 30%;
      padding: 10px 0 10px 0;
    }
}

.precentageTd{
  width: 100%;
}
