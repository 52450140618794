button#tour {
    margin: '15px';
    background-color: '#ff6b2b';
    border: '1px solid transparent';
    transition: 'ease-in-out';
}

button#tour:hover {
    background-color: 'white';
    border: '1px solid #ff6b2b';
    color: '#ff6b2b';
}