.toggleButton {
    background: white;
    padding-left: 30px;
    padding-right: 30px;
}

.MonthlyOff {
    @extend .toggleButton;
    border-color: transparent;
    border-radius: 25px;
}

.MonthlyOn {
    @extend .toggleButton;
    border-color: #00FFFF;
    border-radius: 25px;
}

.YearlyOff {
    @extend .toggleButton;
    border-color: transparent;
    border-radius: 25px;
}

.YearlyOn {
    @extend .toggleButton;
    border-color: #00FFFF;
    border-radius: 25px;
}


