/* Add this to your CSS file or within a <style> tag in your React component */
  .header {
    /* Initial styles */
    max-height: 81px;
    font-weight: bold;
    padding-left: 15px;
    padding-top: 10px;
    font-size: 16px;
    display: grid;
    grid-template-columns: 1fr 0fr;
    transition: background-color 0.3s; /* Smooth transition for hover effects */
    margin-bottom: 0;
  }
  
  .header:hover {
    background-color: #f0f0f0; /* Light grey background on hover */
    display: grid;
    grid-template-columns: 0.005fr 1fr 0.02fr;
    border-radius: 1rem;
    color: green;

  }

.handle {
    display: none; /* Hide by default */
    white-space: nowrap;
    font-size: 10px;
  }
  
  .header:hover .handle {
    display: grid; /* Show when header is hovered */
  }

  .edit-button-container {
    display: none; /* Hide by default */
  }
  
  .header:hover .edit-button-container {
    display: block; /* Show on hover */
  }

.rightContent {
  padding-left: 245px;
}

.board-view {
  background: #fff;
}

.fullscreen-enabled .board-view {
  background: #fff;
}

.hide-in-fullscreen {
  display: none !important;
}

.content-container h2 {
  margin-top: -2px;
  margin-bottom: 0px;
}
.content-container h3 {
  margin-top: -4px;
  margin-bottom: -2px;
}
.content-container ul {
  margin-top: 0;
  margin-bottom: 0;
}
.content-container li {
  margin-top: 0;
  margin-bottom: 0;
}
.content-container section {
  margin-top: 0;
  margin-bottom: 0;
}

.anchor-style {
  background-color: #fff;
  max-width: 400px;
  height: auto;
  cursor: pointer;
  border: 1px solid rgb(222, 222, 223);
  border-radius: 10px;
  gap: 10px;
  color: #000;
  text-decoration: none;
  transition: all 0.3s ease-in-out; /* Smooth transition for hover effect */
}

.anchor-style:hover {
  box-shadow: 0 0 15px rgba(0,0,0,0.1); 
  color: #008000;
}

  
  