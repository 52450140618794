.forcastspage_container {
  font-family: "Cerebri Sans", sans-serif;
  width: 80%;
  /*margin: 1rem auto;*/
  margin-top: 40px;
  margin-left: auto;
  margin-right: auto;

  .forcasts_nav {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    #btn{
      @media only screen and (max-width: 1600px){
        font-size: .8rem;
      }
      @media only screen and (max-width: 1450px){
        font-size: .7rem;
      }
      a{
        @media only screen and (max-width: 1600px){
          padding: .5rem .8rem;
        }
        @media only screen and (max-width: 1450px){
          padding: .5rem .5rem;
        }
      }
    }
  }

}
